import { render, staticRenderFns } from "./CreateCaNonCa.vue?vue&type=template&id=e64e3d50&scoped=true&"
import script from "./CreateCaNonCa.vue?vue&type=script&lang=js&"
export * from "./CreateCaNonCa.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e64e3d50",
  null
  
)

export default component.exports