<template>
  <PageHeader
      title="Field Survey"
      back
      @back="toCustomer"
  >
    <Stepper
        :tabs="tabs"
        :captions="captions"
        :complete="complete"
        @position="stepChange($event)"
        ref="stepper"
        v-if="!!customer"
        vertical
    >

      <template v-slot:CorridorAwarenessDetails>
        <CorridorAwarenessDetails
            ref="fieldCorridorAwarenessStep"
            v-model="fieldCorridorAwarenessFormFields"
            :fieldSurveyAssessment="fieldSurveyAssessment"
            @next="nextStep"
        >
        </CorridorAwarenessDetails>

      </template>


      <template v-slot:VisibleMitigation>
        <VisibleMitigation
            ref="visibleMitigationStep"
            :field="fieldSurveyAssessment"
            v-model="visibleMitigationFormFields"
        >

          <div class="text-left mt-3">
            <v-btn color="primary" @click="saveVisibleMitigationAndGoToMapField" :disabled="!isVisibleMitigationFormFieldsValid" > Save and map field</v-btn>
          </div>
        </VisibleMitigation>

      </template>


    </Stepper>
    <v-row v-if="showGoToFieldMap" class="mx-4 my-4">
      <v-btn color="primary" class="mr-3 mb-2 d-xs-block" @click="saveAndDone">Submit</v-btn>
      <v-btn  color="gray darken-3" class="mb-2" @click="toFieldMapping">Go to map field</v-btn>
    </v-row>
  </PageHeader>
</template>

<script>
import Stepper from "@/components/Stepper";
import FieldSurvey from "@/views/partners/eco_bw/views/fieldSurvey/FieldSurvey";
import LandSizeInput from "@/components/LandSizeInput";
import Row from "@/components/Row";
import LocationInput from "@/components/LocationInput";
import CorridorAwarenessDetails from "@/views/partners/eco_bw/views/fieldSurvey/CorridorAwarenessDetails";
import VisibleMitigation from "@/views/partners/eco_bw/views/fieldSurvey/VisibleMitigation";
import TextInput from "@/components/TextInput";
import {checkVisibleMitigationValid, WalkFieldType} from "@/views/partners/eco_bw/views/fieldSurvey/field-survey";
import SelectInput from "@/components/SelectInput";
import NewFieldForm from "@/views/partners/eco_bw/views/fieldSurvey/NewFieldForm";
import {createEmptyFieldSurveyAssessment} from "@/views/partners/eco_bw/utils";
import fields from "@/mixins/fields";
import PageHeader from "@/components/PageHeader";

export default {
  components: {
    Stepper,
    FieldSurvey,
    LocationInput,
    SelectInput,
    Row,
    LandSizeInput,
    CorridorAwarenessDetails,
    VisibleMitigation,
    TextInput,
    NewFieldForm,
    PageHeader
  },
  data() {
    return {
      showGoToFieldMap: false,
      fieldSurveyAssessment: {
        fieldCorridorAwarenessFormFields: null,
        visibleMitigationFormFields: null,
        fieldUuid: null,
        uuid: null,
        assessmentName: 'Field Survey'
      },
      walkFieldType:WalkFieldType,
      tabs: [
        'CorridorAwarenessDetails',
        'VisibleMitigation',
      ],
      captions: {
        'VisibleMitigation': 'Visible Mitigation',
        'CorridorAwarenessDetails': 'Field Details',
      },
      fieldSurveyFormField: {
        location: null,
        cropId: null,
        fieldSize: null,
        fieldType: null,
      },
      conservationAgricultureFormFields: null,
      visibleMitigationFormFields: null,
      fieldCorridorAwarenessFormFields: {
        fieldRegistrationDate: null,
        evidenceFieldExpansionOntoElephantCorridor: false,
        areCorridorsHelping: null,
        peopleSupportedByField: {
          label: 'People supported by field',
          answer: null
        },
        ownership: {
          label: 'Ownership',
          answer: null
        }
      },
    }
  },
  mounted() {
    // if field exist update form data, if no field dont do anything
    if (!this.customer.fields) {
      this.customer.fields = []
    }
    if (this.fieldSurveyAssessment) { 
      this.fieldSurveyAssessment.fieldUuid = this.fieldUuid;
      this.fieldSurveyAssessment.uuid = this.fieldUuid;
     }
    if (this.fieldAssessment) {
      this.fieldCorridorAwarenessFormFields = this.fieldAssessment.fieldCorridorAwarenessFormFields;
      this.conservationAgricultureFormFields = this.fieldAssessment.conservationAgricultureFormFields;
      this.visibleMitigationFormFields = this.fieldAssessment.visibleMitigationFormFields;
    }

    if (this.fieldSurveyAssessment && this.fieldSurveyAssessment.fieldCorridorAwarenessFormFields) {
      this.fieldCorridorAwarenessFormFields = this.fieldSurveyAssessment.fieldCorridorAwarenessFormFields;
    }
    if (this.fieldSurveyAssessment && this.fieldSurveyAssessment.conservationAgricultureFormFields) {
      this.conservationAgricultureFormFields = this.fieldSurveyAssessment.conservationAgricultureFormFields;
    }
    if (this.fieldSurveyAssessment && this.fieldSurveyAssessment.visibleMitigationFormFields) {
      this.visibleMitigationFormFields = this.fieldSurveyAssessment.visibleMitigationFormFields;
    }
  },

  computed: {
    customerUuid() { return this.$route.query.uuid; },
    customer() { return this.$store.getters.customer(this.$route.query.uuid); },
    complete() { return [] },
    outerFields() {
      return this.customer.fields ? this.customer.fields.filter(f => f.fieldType === 'outer field') : [];
    },
    crops() { return this.$store.getters.crops; },
    fieldUuid() { return this.$route.query.farmUuid; },
    field() { return this.customer.fields ?this.customer.fields.find( f => f.uuid===this.fieldUuid ) : null; },
    isConservationAgricultureFormFieldsValid() {
      let x = false
      if (this.conservationAgricultureFormFields) {
        x = Object.values(this.conservationAgricultureFormFields).filter(formField => {
              return formField.hasOwnProperty("fieldType") && formField.fieldType !== null && formField.fieldType !== undefined && !formField.answer;
            });
        x = x.filter(item => ![true, false].includes(item.answer)).filter(item => ![true, false].includes(item.answer)).length === 0
      };
      return x
    },
    isVisibleMitigationFormFieldsValid() {
      return checkVisibleMitigationValid(this.visibleMitigationFormFields);
    },
    fieldAssessment(){
      const assessments = this.$store.getters.assessments.filter((obj) => obj.fieldUuid === this.fieldUuid && obj.customerUuid === this.customerUuid);
      return assessments.length > 0 ? assessments[0] : null;
    }
  },
  methods: {
    stepChange(to) {
      this.firstStep = (to==='first');
      this.lastStep = (to==='last');
    },
    nextStep() {
      this.$refs.stepper.nextStep();
    },
    saveFieldSection() {
      this.fieldSurveyAssessment = createEmptyFieldSurveyAssessment(this.customerUuid, this.fieldUuid);
      this.nextStep();
    },
    saveVisibleMitigationAndGoToMapField() {
      this.saveAndDone()
      this.toFieldMapping();
    },
    saveFieldCorridorAwareness() {
      this.fieldSurveyAssessment.fieldCorridorAwarenessFormFields = this.fieldCorridorAwarenessFormFields
      this.nextStep();
    },

    saveConservationAgriculture() {
      this.$refs.conservationAgricultureStep.validate()
      if (this.conservationAgricultureFormFields) {
        if (this.conservationAgricultureFormFields.fieldType) {
          this.fieldSurveyFormField.fieldType = this.conservationAgricultureFormFields.fieldType
          this.$store.dispatch('upsertField', this.fieldSurveyFormField);
        }
        this.fieldSurveyAssessment.conservationAgricultureFormFields = this.conservationAgricultureFormFields
      }
      
      this.nextStep();
    },
    saveAndDone() {
      this.fieldSurveyAssessment = this.fieldAssessment ? this.fieldAssessment : this.fieldSurveyAssessment
      this.fieldSurveyAssessment.customerUuid = this.customerUuid
      this.fieldSurveyAssessment.assessmentType = 'FieldSurvey'
      this.fieldSurveyAssessment.visibleMitigationFormFields = this.visibleMitigationFormFields
      this.$store.commit('upsertAssessment', this.fieldSurveyAssessment)
    },
    toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
    toFieldMapping() {
      this.$router.push({ name: 'FieldMap', query: { uuid: this.customerUuid, fieldUuid: this.fieldUuid, isFarm: true } });
    },
  },
  mixins: [fields],
}
</script>

<style scoped>
</style>


