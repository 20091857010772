<template>
  <v-form ref="basicForm" v-model="valid" class="text-left" v-if="!!formFields" >
    <div>
      <NameInput v-model="formFields.firstName" :label="$t('First Name')" required />
    </div>
    <div>
      <NameInput v-model="formFields.surname" :label="$t('Surname')" required />
    </div>
    <div>
        <MsisdnInput v-model="customer.msisdn" required counter-number="9" />
    </div>
    <div>
        <IdentityInput v-model="customer.demographic.identity" required />
    </div>

    <div>
        <DateInput
            v-model="formFields.birthDate"
            :year="true"
            :max="(new Date()).toISOString()"
            label="Birth Date"
            dropLists
            required />
    </div>

    <div>
        <label>Gender</label>
        <GenderInput v-model="formFields.gender" required />
    </div>

    <div>
      <v-combobox
          persistent-hint
          v-model="formFields.ethnicity.answer"
          :items="formFields.ethnicity.options"
          label="Ethnicity"
          :rules="[v => !!v || 'Required']"
      ></v-combobox>
    </div>

    <div>

      <v-text-field
          persistent-hint
          v-model="formFields.familySize"
          label="Family Size"
          :rules="[v => !!v || 'Required']"
      ></v-text-field >
    </div>
    <div>
      <v-text-field
          persistent-hint
          v-model="formFields.yearsFarming"
          label="Years Farming"
          :rules="[v => !!v || 'Required']"
      ></v-text-field>
    </div>

    <div>
      <SelectInput
          v-model="formFields.transport"
          :items=transportOptions
          label="Transport" required />
    </div>

    <div>
      <SelectInput
          v-model="formFields.livelihood"
          :items=incomeSources
          label="Livelihood (non-farming)"
          :menu-props="{ top: true, offsetY: true}"
          required
          multiple
      />
    </div>

    <div v-if="showFarmersHomeStead">
      <v-radio-group
          v-model="formFields.atTheFarmersLocationOnRegister"
          label="I am at the farmer`s homestead"
      >
        <v-radio
            label="Yes"
            :value="true"
        ></v-radio>
        <v-radio
            label="No"
            :value="false"
        ></v-radio>
      </v-radio-group>
    </div>

    <div class="ml-auto">

      <PhotoInput
          type="demographic"
          v-model="formFields.farmerImageUuid"
          :typeId="customer.uuid"
          label="farmer"
          required />

      <div>
        <SingleImage
            :image="farmerImageObject"
            maxHeight="200px"
        />

      </div>
    </div>

    <EcoBwRegionPicker
        v-if="!!formFields"
        v-model="formFields.region"
    />

    <slot>
    </slot>
    <v-divider></v-divider>
  </v-form>

</template>

<script>
import MsisdnInput from "@/components/MsisdnInput";
import IdentityInput from "@/components/IdentityInput";
import NameInput from "@/components/NameInput";
import GenderInput from "@/components/GenderInput";
import TextInput from "@/components/TextInput";
import DateInput from "@/components/DateInput";
import SelectInput from "@/components/SelectInput";
import PhotoInput from "@/components/PhotoInput";
import SingleImage from "@/components/SingleImage";
import ImageDb from "@/store/ImageDb";
import EcoBwRegionPicker from "@/views/partners/eco_bw/views/register/EcoBwRegionPicker.vue";

export default {
  name: "BasicInfo",
  components: {
    NameInput,
    MsisdnInput,
    TextInput,
    IdentityInput,
    GenderInput,
    DateInput,
    SelectInput,
    PhotoInput,
    SingleImage,
    EcoBwRegionPicker,
  },

  props: ["value", "next", "customer"],

  data() {
    return {
      valid: false,
      formFields: null,
      farmerImageObject: null,
      showFarmersHomeStead: false,
      incomeSources: [
        "Teacher",
        "Engineer",
        "Other",
        "None",
      ],
      transportOptions: [
        "Bicycle",
        "Oxcart",
        "Truck (vehicle)",
        "Donkey",
        "Walking",
        "Horse",
        "Other",
        "None",
      ],
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.formFields = to;
       }
    },
    formFields:{
      deep: true,
      handler(to) {
        this.$emit('input', to);
      },
    },
    'formFields.farmerImageUuid': function (newValue) {
      if (newValue) {
        ImageDb.url(newValue).then( e => this.farmerImageObject = e );
      }
    }
  },

  methods: {
    validate() {
      this.$refs.basicForm.validate();
    },

    validateAndContinue(){
      this.validate();
      this.next();
    }
  },

}
</script>
