<template>
  <div>
    <div class="d-lg-flex">
      <v-combobox
          :items="partnerParsedRegions"
          v-model="formFields.village"
          item-text="village"
          return-object
          :label="'Village'"
          class="mr-6"
      ></v-combobox >

      <v-combobox
          :items="selectedVillage && selectedVillage.wards"
          :label="'Ward'"
          v-model="formFields.ward"
          class="mr-6"
      ></v-combobox >

    </div>


  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      formFields: {},
      selectedVillage: null
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.formFields = to;
      }
    },
    'formFields.village': function(newValue) {
      this.selectedVillage = newValue
    }
  },

  computed: {
    partnerRawRegions() {
      return this.$store.getters.settings.regions
    },
    partnerParsedRegions() {
      return Object.keys(this.partnerRawRegions).map(village => ({
        village: village,
        wards: Object.keys(this.partnerRawRegions[village].wards),
        locations: Object.keys(this.partnerRawRegions[village].locations),
      }))
    }
  }
}
</script>

<style scoped>

</style>
