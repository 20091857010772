<template>
  <PageHeader :title="fieldName" icon="mdi-flower-outline" @subtitle="toCustomer" @back="toCustomer" back @save="save" save :valid="valid" >
    <v-container>
      <v-row v-if="!waitingForGps">
        <v-col class="text-caption">
          {{$t('info')}}
        </v-col>
      </v-row>

      <v-row v-if="!waitingForGps">
        <v-col class="text-center">
          <v-btn @click="recordMapCoords=false" v-if="recordMapCoords">
            <v-icon class="mr-1">mdi-crosshairs-gps</v-icon>
            {{$t('Stop Recording')}}
          </v-btn>
          <v-btn @click="recordMapCoords=true" v-else>
            <v-icon class="mr-1">mdi-crosshairs-gps</v-icon>
            {{$t('Record Location')}}
          </v-btn>
        </v-col>
      </v-row>

    <v-row v-if="waitingForGps">
        <v-col class="text-center">
          Acquiring GPS location data...
        </v-col>
      </v-row>

      <v-row v-if="waitingForGps">
        <v-col class="text-center">
          <v-progress-circular
            :rotate="-90"
            :size="100"
            :width="15"
            :value="gpsProximity"
            color="primary"
          >
            {{ gpsProximity }} <small>%</small>
          </v-progress-circular>
        </v-col>
      </v-row>


      <v-row v-if="gpsErrorStatus">
        <v-col class="text-center">
          <p class="red--text mt-2">{{gpsErrorStatus}}</p>
        </v-col>
      </v-row>

      <v-row v-if="gpsAccuracy">
        <v-col class="text-h5 text-center" v-if="gpsOkay">
          {{$t('Accuracy')}}:
          {{gpsRoundedAccuracy}} m
        </v-col>
        <v-col class="text-h5 text-center red--text" v-else>
          {{$t('Accuracy')}}:
          {{gpsRoundedAccuracy}} m
          <span v-if="!waitingForGps">(GPS Lost - locations will not be recorded)</span>
        </v-col>
      </v-row>


      <v-row v-if="showPlot">
        <v-col>
          <MapPlot :coords="locations" />
        </v-col>
      </v-row>

      <v-row v-if="!waitingForGps">
        <v-col class="text-caption">
          <span v-if="area">{{$t('Area')}}: {{area}} m<sup>2</sup> ({{hectares}} ha). {{$t('Perimeter')}}: {{perimeter}} m.</span>
          <span @click="showTable = !showTable">Points: {{locations.length}}.</span>
        </v-col>
      </v-row>

      <v-row v-if="showTable">
        <v-col>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>{{$t('Location')}}</th>
                  <th>{{$t('Accuracy')}}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pos,index) in locations" :key="index" >
                  <td class="text-left">{{ index + 1 }}</td>
                  <td class="text-left"><LatLong :latitude="pos.latitude" :longitude="pos.longitude" /></td>
                  <td class="text-left">{{ Math.round( pos.accuracy*10 )/10 }} m</td>
                  <td class="text-center">
                     <a href="#" class="text-decoration-none" @click="delete_entry(pos.index)">
                      <v-icon small>mdi-delete</v-icon>
                     </a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

    </v-container>

  </PageHeader>

</template>


<script>

  var GeographicLib = require("geographiclib");

  import gps from '@/mixins/gps';

  import PageHeader from '@/components/PageHeader';
  import MapPlot from '@/components/MapPlot';
  import Heading from '@/components/Heading';
  import LatLong from '@/components/LatLong';

  export default {

    components: {
      PageHeader,
      MapPlot,
      Heading,
      LatLong
    },

    data: () => ({
      locations: [],
      area: null,
      perimeter: null,
      recordMapCoords: false,
      showTable: false,
    }),

    computed: {
      farmsForCustomers() { return this.$store.getters.farmsForCustomers(this.customerUuid) },
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      customerUuid() { return this.$route.query.uuid; },
      fieldUuid() { return this.$route.query.fieldUuid; },
      isFarm() { return this.$route.query.isFarm },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      field() {
        if (this.isFarm) {
          return this.$store.getters.farmsForCustomers(this.customerUuid).filter(e => e.uuid == this.fieldUuid)[0]; 
        } else {
          return this.$store.getters.fieldsForCustomers(this.customerUuid).filter(e => e.uuid == this.fieldUuid)[0];
        }
      },

      fieldName() {
        let f = this.field ? this.field.name : 'New Field';
        if (f.length>16) { f = f.replace(/Hectares/, 'ha'); }
        if (this.partnerCode === 'eco_bw') {
          f = "Map field"
        }
        return f;
      },

      showPlot() { return (this.locations.length>2); },

      hectares() { return (this.area ? (this.area/10000).toFixed(2) : null); },

      valid() { return this.locations.length>=3; },

    },

    watch: {
      locations: function() { this.calculateArea(); },
    },

    methods: {
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
      toField() { this.$router.push({ name: 'Field', query: { uuid: this.customerUuid, fieldUuid: this.field.uuid } }); },


      delete_entry(index) {
        let i = this.locations.findIndex( e => e.index===index );
        this.locations.splice(i, 1);
      },

      midpoint() {
        let latitude = 0;
        let longitude = 0;
        let count = 0;
        this.locations.forEach( e => { latitude = latitude + e.latitude; longitude = longitude + e.longitude; count = count + 1 } );
        return { latitude: latitude/count, longitude: longitude/count };
      },

      save() {
        this.field.map = this.locations;
        if (this.field.location==null) { this.field.location = this.midpoint(); }
        this.field.measured_m2 = this.area;
        this.field.perimeter = this.perimeter;
        this.$store.dispatch('upsertCustomer', this.customer);
        if (this.isFarm) {
          this.$store.dispatch('upsertField', this.field); 
          this.$router.push({name: 'FarmField', query: { uuid: this.customerUuid, farmUuid: this.fieldUuid }});
        } else {
          this.$store.dispatch('upsertField', this.field); 
          this.$router.push({name: 'Field', query: { uuid: this.customerUuid, fieldUuid: this.fieldUuid }});
        }
      },

      calculateArea() {
        if (this.locations.length>2) {
          let Geodesic = GeographicLib.Geodesic;
          let geod = Geodesic.WGS84;
          let lat_longs = this.locations.map( e => [e.latitude, e.longitude] );
          let p = geod.Polygon(false);
          for (let i = 0; i < lat_longs.length; ++i) { p.AddPoint(lat_longs[i][0], lat_longs[i][1]); }
          p = p.Compute(false, true);
          this.area = Math.abs(p.area.toFixed(0));
          this.perimeter = p.perimeter.toFixed(0);
        } else {
          this.area = null;
          this.perimeter = null;
        }
      },

      gpsUpdate(coords) { if (this.recordMapCoords && this.gpsOkay) { this.locations.push(coords);} },

    },

    mixins: [gps],

  };
</script>


<i18n>
{
  "en": {
    "Record Location": "Start Mapping",
    "Accuracy": "Accuracy",
    "Location": "Location",
    "Area": "Area",
    "or": "or",
    "Perimeter": "Perimeter",
    "Last read failed!": "Last read failed!",
    "Unable to retrieve location. Please retry.": "Unable to retrieve location. Please retry.",
    "info": "Walk around the area you wish to measure.",
    "Initial": "Initial",
    "Current": "Current",
    "Last": "Last"
  },
  "es": {
    "Record Location": "Empezar a mapear",
    "Accuracy": "Precisión",
    "Location": "La Ubicación",
    "Area": "Vereda",
    "or": "o",
    "Perimeter": "Perímetro",
    "Last read failed!": "¡Última lectura fallida!",
    "Unable to retrieve location. Please retry.": "No se pudo recuperar la ubicación. Por favor, intenta de nuevo.",
    "info": "Camine por el área que desea medir.",
    "Initial": "Inicial",
    "Current": "Actual",
    "Last": "Último"
  },
  "sw": {
    "Record Location": "Anza ramani",
    "Accuracy": "Usahihi",
    "Location": "Mahali",
    "Area": "Eneo",
    "or": "au",
    "Perimeter": "Mzunguko",
    "Last read failed!": "Kusoma mwisho kulishindwa!",
    "Unable to retrieve location. Please retry.": "Imeshindwa kupata eneo. Tafadhali jaribu tena.",
    "info": "Tembea karibu na eneo unalotaka kupima.",
    "Initial": "Awali",
    "Current": "Sasa",
    "Last": "Mwisho"
  }
}
</i18n>
