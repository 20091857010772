<template>
  <PageHeader :title="customer.name"  v-if="customer" back save @back="toCustomer" @save="save" :valid='formValid' >

    <FormContainer>
      <v-row>

        <v-col cols="12" sm="6" md="4" lg="3">
          <GenderInput v-model="customer.demographic.gender" />
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <DateInput
            v-model="customer.demographic.birthDate"
            :label="$t('Birth Date')"
            min="1910-01-01"
            :max="new Date().toISOString().substr(0, 10)"
            dropLists
          />
        </v-col>

        <v-col v-if="partnerCode != 'eco_bw'" cols="6" md="4" lg="3">
          <v-select
            v-model="customer.demographic.dependents"
            :label="$t('Dependents')"
            :items="[0,1,2,3,4,5,6,7,8]"
          ></v-select>
        </v-col>

        <v-col cols="6" md="4" lg="3">
          <v-select
            v-model="customer.demographic.schooling"
            :label="$t('Years of schooling')"
            :items="schoolingOptions"
          ></v-select>
        </v-col>

        <v-col cols="6" md="4" lg="3" v-if="showLanguage">
          <LanguageInput v-model="customer.demographic.language" />
        </v-col>
        <v-col cols="6" md="4" lg="3" v-if="customer.economic.payload.basicInfo">
          <SelectInput v-model="customer.economic.payload.basicInfo.ethnicity.answer" :items="['Bambukushu', 'Basarwa', 'Bayei','Badxeriku','Batawana','Bakalaka','Banyemba','Baweku','Basubiya']" label="Ethnicity" required/>
        </v-col>

        <v-col cols="6" md="4" lg="3" v-if="customer.economic.payload.basicInfo">
          <SelectInput v-model="customer.economic.payload.basicInfo.familySize" :items="[1,2,3,4,5,6,7]" label="Family Size"/>
        </v-col>

        <v-col cols="6" md="4" lg="3" v-if="customer.economic.payload.basicInfo">
          <SelectInput v-model="customer.economic.payload.basicInfo.yearsFarming" :items="['1','2','3','4','5','6','7','8','9','10+']" label="Years farming"/>
        </v-col>

        <v-col cols="6" md="4" lg="3" v-if="customer.economic.payload.basicInfo">
          <SelectInput v-model="customer.economic.payload.basicInfo.transport" :items=transportOptions label="Transport" multiple/>
        </v-col>

        <v-col cols="6" md="4" lg="3" v-if="customer.economic.payload.basicInfo">
          <SelectInput v-model="customer.economic.payload.basicInfo.livelihood" :items=incomeSources label="Livelihood (non-farming)" multiple/>
        </v-col>
      </v-row>
    </FormContainer>


  </PageHeader>
</template>


<script>
  import PageHeader from '@/components/PageHeader';
  import Row from '@/components/Row';
  import FormContainer from '@/components/FormContainer';
  import DateInput from '@/components/DateInput';
  import GenderInput from '@/components/GenderInput';
  import LanguageInput from '@/components/LanguageInput';
  import SelectInput from "@/components/SelectInput";

  export default {

    components: { PageHeader, Row, SelectInput, FormContainer, DateInput, GenderInput, LanguageInput },

    data: () => ({
      formValid: true,
      incomeSources: [
        "Teacher",
        "Engineer",
        "Other",
        "None"
      ],
      transportOptions: [
        "Bicycle",
        "Oxcart",
        "Truck (vehicle)",
        "Donkey",
        "Horse",
        "Other",
        "None",
      ],
    }),

    computed: {
      uuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.uuid); },

      partnerCode() { return this.$store.getters.settings.partnerCode; },
      showLanguage() { return !['apex_tz', 'hr_co', 'winwin_zw', 'eco_bw'].includes(this.partnerCode); },

      schoolingOptions() {
        let options = [0,1,2,3,4,5,6,7,8,9,10,11,12];
        if (this.partnerCode==='uzima_ug') { options = ['Primary Level', 'Senior four Level', 'Senior Six level', 'Certificate Holder', 'Diploma Holder', 'Degrees Holder']; }
        return options;
      }
    },

    methods: {
      save() {
        this.$store.dispatch('upsertCustomer', this.customer);
        this.toCustomer();
      },
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.uuid } }); },
    },

  }
</script>


<i18n>
{
  "en": {
    "Birthdate": "Birthdate",
    "Dependents": "Dependents",
    "Years of schooling": "Years of schooling"
  },
  "es": {
    "Birthdate": "Fecha de Nacimiento",
    "Dependents": "Personas a cargo (económicamente)",
    "Years of schooling": "Años de Escolaridad"
  },
  "sw": {
    "Birthdate": "Siku ya kuzaliwa",
    "Dependents": "Wategemezi",
    "Years of schooling": "Kusoma"
  }
}
</i18n>
